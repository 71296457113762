import isEqual from 'lodash/isEqual';
import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';

import { DAYS_OF_THE_WEEK } from 'src/config';

interface BrandCampaignPlaylistFilterContext {
  venueIds?: number[];
  locationIds?: number[];
  mediaTagIds?: number[];
  locationTagIds?: number[];
  startDate?: string;
  endDate?: string;
  dailyStartTime?: string;
  dailyEndTime?: string;
  daysOfWeek: (keyof typeof DAYS_OF_THE_WEEK)[];
}

const BrandCampaignPlaylistFilterContext = createContext({
  brandCampaignPlaylistFilterValues: {} as BrandCampaignPlaylistFilterContext,
  setBrandCampaignPlaylistFilterValues: {} as Dispatch<SetStateAction<BrandCampaignPlaylistFilterContext>>,
});

const noFiltersApplied: BrandCampaignPlaylistFilterContext = {
  venueIds: [],
  locationIds: [],
  mediaTagIds: [],
  locationTagIds: [],
  startDate: undefined,
  endDate: undefined,
  dailyStartTime: undefined,
  dailyEndTime: undefined,
  daysOfWeek: [],
};

export const useBrandCampaignPlaylistFilterContext = () => {
  const { brandCampaignPlaylistFilterValues, setBrandCampaignPlaylistFilterValues } = useContext(
    BrandCampaignPlaylistFilterContext
  );

  const [contextValue, setContextValue] = useState<BrandCampaignPlaylistFilterContext>({
    venueIds: [],
    locationIds: [],
    mediaTagIds: [],
    locationTagIds: [],
    startDate: undefined,
    endDate: undefined,
    dailyStartTime: undefined,
    dailyEndTime: undefined,
    daysOfWeek: [],
  });

  const hasFiltersApplied = useMemo(() => {
    return !isEqual(brandCampaignPlaylistFilterValues, noFiltersApplied);
  }, [brandCampaignPlaylistFilterValues]);

  const handleClearFilters = () => {
    setBrandCampaignPlaylistFilterValues(noFiltersApplied);
  };

  const brandCampaignPlaylistDefaultValueForContextProvider = {
    brandCampaignPlaylistFilterValues: contextValue,
    setBrandCampaignPlaylistFilterValues: setContextValue,
  };

  return {
    brandCampaignPlaylistDefaultValueForContextProvider,
    brandCampaignPlaylistFilterValues,
    setBrandCampaignPlaylistFilterValues,
    handleClearFilters,
    hasFiltersApplied,
    BrandCampaignPlaylistFilterContext,
  };
};
