import isEqual from 'lodash/isEqual';
import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';

interface DeviceFilterContext {
  venueIds?: number[];
  locationIds?: number[];
  status?: string;
}

const DeviceFilterContext = createContext({
  deviceFilterValues: {} as DeviceFilterContext,
  setDeviceFilterValues: {} as Dispatch<SetStateAction<DeviceFilterContext>>,
});

const noFiltersApplied: DeviceFilterContext = {
  venueIds: [],
  locationIds: [],
  status: undefined,
};

export const useDeviceFilterContext = () => {
  const { deviceFilterValues, setDeviceFilterValues } = useContext(DeviceFilterContext);

  const [contextValue, setContextValue] = useState<DeviceFilterContext>({
    venueIds: [],
    locationIds: [],
    status: undefined,
  });

  const hasFiltersApplied = useMemo(() => {
    return !isEqual(deviceFilterValues, noFiltersApplied);
  }, [deviceFilterValues]);

  const handleClearFilters = () => {
    setDeviceFilterValues(noFiltersApplied);
  };

  const deviceDefaultValueForContextProvider = {
    deviceFilterValues: contextValue,
    setDeviceFilterValues: setContextValue,
  };

  return {
    deviceDefaultValueForContextProvider,
    deviceFilterValues,
    setDeviceFilterValues,
    handleClearFilters,
    hasFiltersApplied,
    DeviceFilterContext,
  };
};
