import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { brandCampaignGETByIDPath } from 'src/features/brandCampaigns/api/paths';
import { CampaignByIDResponse } from 'src/features/campaigns';
import { httpClient } from 'src/lib/axios';

type GetBrandCampaignByIdDTO = {
  data: {
    brandId: number | string;
    campaignId: number | string;
    queryParams?: {
      [key: string]: string;
    };
  };
};

const getBrandCampaignById = async ({ data }: GetBrandCampaignByIdDTO): Promise<CampaignByIDResponse> => {
  const { brandId, campaignId, queryParams } = data;

  const {
    data: { payload },
  } = await httpClient.get(`${brandCampaignGETByIDPath(brandId, campaignId)}`, {
    params: queryParams,
  });

  return payload as CampaignByIDResponse;
};

export const constructGetBrandCampaignByIdDTO = (
  brandId: string | number | undefined,
  campaignId: string | number | undefined,
  queryParams?: { [key: string]: string }
): GetBrandCampaignByIdDTO => {
  return {
    data: {
      brandId: brandId as string | number,
      campaignId: campaignId as string | number,
      queryParams,
    },
  };
};

export const useGetBrandCampaign = (
  payload: GetBrandCampaignByIdDTO,
  options?: UseQueryOptions<CampaignByIDResponse>
) => {
  const {
    data: { campaignId },
  } = payload;

  return useQuery<CampaignByIDResponse>({
    queryKey: ['brandCampaign', Number(campaignId)],
    queryFn: () => getBrandCampaignById(payload),
    keepPreviousData: true,
    ...options,
  });
};
