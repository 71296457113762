import { useFormState } from 'react-hook-form';

export const useFormHelpers = () => {
  const { isSubmitting, errors, isValid, dirtyFields } = useFormState();

  // on regular 'create' forms, the disabled submit condition doesn't include the form being dirty
  const isSubmitDisabled = () => {
    if (isSubmitting || Object.keys(errors).length || !isValid) {
      return true;
    }

    return false;
  };

  // on 'update' forms, the disabled submit condition has to also check for the form being dirty
  const isSubmitDisabledForDetailsForm = () => {
    if (isSubmitting || Object.keys(errors).length || !isValid || !Object.keys(dirtyFields).length) {
      return true;
    }

    return false;
  };

  return {
    isSubmitDisabled,
    isSubmitDisabledForDetailsForm,
  };
};
