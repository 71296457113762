import { useCallback, useState } from 'react';

export const useTagDialog = () => {
  const [isTagDialogOpen, setIsTagDialogOpen] = useState<boolean>(false);

  return {
    tagDialog: {
      isOpen: isTagDialogOpen,
      handleOpen: useCallback(() => setIsTagDialogOpen(true), []),
      handleClose: useCallback(() => setIsTagDialogOpen(false), []),
    },
  };
};
