const BASE_BRAND_PATH = '/brand';

export const brandGETPath = () => `${BASE_BRAND_PATH}`;
export const brandGETByIDPath = (id: number | string) => `${BASE_BRAND_PATH}/${id}`;

export const brandPOSTPath = () => `${BASE_BRAND_PATH}`;

export const brandPUTPath = (id: number | string) => `${BASE_BRAND_PATH}/${id}`;

export const brandDELETEPath = (id: number | string) => `${BASE_BRAND_PATH}/${id}`;
