import { Auth0Provider } from '@auth0/auth0-react';
import { ReactElement } from 'react';

import { auth0 } from 'src/config';

interface AuthProviderProps {
  children: ReactElement[] | ReactElement;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  return (
    <Auth0Provider
      domain={auth0.domain}
      clientId={auth0.clientId}
      authorizationParams={{
        audience: auth0.audience,
        redirect_uri: window.location.origin,
      }}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};
