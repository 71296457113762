import loadable from '@loadable/component';
import { Navigate, Outlet } from 'react-router-dom';

// Default level routes
const AdminUserRoutes = loadable(() => import('src/features/adminUsers'), {
  resolveComponent: components => components.AdminUserRoutes,
});
const BrandRoutes = loadable(() => import('src/features/brands'), {
  resolveComponent: components => components.BrandRoutes,
});
const CampaignRoutes = loadable(() => import('src/features/campaigns'), {
  resolveComponent: components => components.CampaignRoutes,
});
const VenueRoutes = loadable(() => import('src/features/venues'), {
  resolveComponent: components => components.VenueRoutes,
});
const PairDeviceRoutes = loadable(() => import('src/features/pairDevices'), {
  resolveComponent: components => components.PairDeviceRoutes,
});
const DeviceRoutes = loadable(() => import('src/features/devices'), {
  resolveComponent: components => components.DeviceRoutes,
});
const DeviceMetricsRoutes = loadable(() => import('src/features/deviceMetrics'), {
  resolveComponent: components => components.DeviceMetricsRoutes,
});
const AdsRoutes = loadable(() => import('src/features/ads'), {
  resolveComponent: components => components.AdsRoutes,
});
const PromoRoutes = loadable(() => import('src/features/promos'), {
  resolveComponent: components => components.PromoRoutes,
});

// Brand routes
const BrandUserRoutes = loadable(() => import('src/features/brandUsers'), {
  resolveComponent: components => components.BrandUserRoutes,
});
const BrandMediaRoutes = loadable(() => import('src/features/brandMediaLibrary'), {
  resolveComponent: components => components.MediaRoutes,
});
const BrandCampaignRoutes = loadable(() => import('src/features/brandCampaigns'), {
  resolveComponent: components => components.BrandCampaignRoutes,
});
const BrandCampaignPlaylistRoutes = loadable(() => import('src/features/brandCampaignPlaylists'), {
  resolveComponent: components => components.BrandCampaignPlaylistRoutes,
});

// Venue routes
const VenueLocationRoutes = loadable(() => import('src/features/locations'), {
  resolveComponent: components => components.LocationRoutes,
});
const VenuePlaylistRoutes = loadable(() => import('src/features/venuePlaylists'), {
  resolveComponent: components => components.VenuePlaylistRoutes,
});
const VenueTagRoutes = loadable(() => import('src/features/tags'), {
  resolveComponent: components => components.TagRoutes,
});
const VenueMediaRoutes = loadable(() => import('src/features/venueMediaLibrary'), {
  resolveComponent: components => components.VenueMediaRoutes,
});
const VenueUserRoutes = loadable(() => import('src/features/venueUsers'), {
  resolveComponent: components => components.VenueUserRoutes,
});

const VenueAnalyticsRoutes = loadable(() => import('src/features/venueAnalytics'), {
  resolveComponent: components => components.VenueAnalyticsRoutes,
});

const App = () => {
  return <Outlet />;
};

export const protectedRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: 'ads/*', element: <AdsRoutes /> },
      { path: 'users/*', element: <AdminUserRoutes /> },
      { path: 'pair-devices/*', element: <PairDeviceRoutes /> },
      { path: 'devices/*', element: <DeviceRoutes /> },
      { path: 'device-metrics/*', element: <DeviceMetricsRoutes /> },
      { path: 'brands/*', element: <BrandRoutes /> },
      { path: 'brands/:brandId/users/*', element: <BrandUserRoutes /> },
      { path: 'brands/:brandId/library/*', element: <BrandMediaRoutes /> },
      { path: 'brands/:brandId/campaigns/*', element: <BrandCampaignRoutes /> },
      { path: 'brands/:brandId/campaigns/:campaignId/playlist/*', element: <BrandCampaignPlaylistRoutes /> },
      { path: 'campaigns/*', element: <CampaignRoutes /> },
      { path: 'promos/*', element: <PromoRoutes /> },
      { path: 'venues/*', element: <VenueRoutes /> },
      { path: 'venues/:venueId/library/*', element: <VenueMediaRoutes /> },
      { path: 'venues/:venueId/locations/*', element: <VenueLocationRoutes /> },
      { path: 'venues/:venueId/playlist/*', element: <VenuePlaylistRoutes /> },
      { path: 'venues/:venueId/analytics/*', element: <VenueAnalyticsRoutes /> },
      { path: 'venues/:venueId/tags/*', element: <VenueTagRoutes /> },
      { path: 'venues/:venueId/users/*', element: <VenueUserRoutes /> },
      { path: '/', element: <Navigate to="campaigns" /> },
      { path: '/*', element: <Navigate to="campaigns" /> },
    ],
  },
];
