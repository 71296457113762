import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { BrandResponse } from 'src/features/brands';
import { brandGETByIDPath } from 'src/features/brands/api/paths';
import { httpClient } from 'src/lib/axios';

type GetBrandByIdDTO = {
  data: {
    brandId: number | string;
  };
};

const getBrandById = async ({ data }: GetBrandByIdDTO): Promise<BrandResponse> => {
  const { brandId } = data;

  const {
    data: { payload },
  } = await httpClient.get(`${brandGETByIDPath(brandId)}`);

  return payload as BrandResponse;
};

export const constructGetBrandByIdDTO = (brandId: string | number | undefined): GetBrandByIdDTO => {
  return {
    data: {
      brandId: brandId as string | number,
    },
  };
};

export const useGetBrand = (payload: GetBrandByIdDTO, options?: UseQueryOptions<BrandResponse>) => {
  const {
    data: { brandId },
  } = payload;

  return useQuery<BrandResponse>({
    queryKey: ['brand', Number(brandId)],
    queryFn: () => getBrandById(payload),
    keepPreviousData: true,
    ...options,
  });
};
