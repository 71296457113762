import { ReactElement, useEffect } from 'react';

import { Head } from 'src/components/Head';
import { usePageTitleContext } from 'src/contexts/pageTitleContext';

type ContentLayoutProps = {
  children: ReactElement | ReactElement[];
  title: string;
};

export const ContentLayout = ({ children, title }: ContentLayoutProps) => {
  const { setPageTitle } = usePageTitleContext();

  useEffect(() => {
    setPageTitle(title);
  }, [setPageTitle, title]);

  return (
    <div>
      <Head title={title} />
      <div>{children}</div>
    </div>
  );
};
