import { useAuth0 } from '@auth0/auth0-react';
import { ReactElement } from 'react';

import { GenericErrorPage } from 'src/features/miscellaneous';

interface SecureProviderProps {
  children: ReactElement[] | ReactElement;
}

export const SecureProvider = ({ children }: SecureProviderProps): JSX.Element | null => {
  const { error, isLoading, isAuthenticated, logout } = useAuth0();

  if (isLoading) {
    return null;
  }

  if (!isAuthenticated && !error) {
    async () => {
      logout({ logoutParams: { returnTo: window.location.origin } });
    };
  }

  if (error) {
    return <GenericErrorPage error={error} />;
  }

  return <>{children}</>;
};
