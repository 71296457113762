import { styled } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';

export const StyledContainer = styled(() => (
  <ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    theme="colored"
    pauseOnHover
  />
))(() => ({}));

export const ToastProvider = () => {
  return <StyledContainer />;
};
