import isEqual from 'lodash/isEqual';
import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';

import { MediaType } from 'src/features/media';
interface VenueMediaFilterContext {
  tagIds?: number[];
  types?: MediaType[];
}

const noFiltersApplied: VenueMediaFilterContext = {
  tagIds: [],
  types: [],
};

const VenueMediaFilterContext = createContext({
  venueMediaFilterValues: {} as VenueMediaFilterContext,
  setVenueMediaFilterValues: {} as Dispatch<SetStateAction<VenueMediaFilterContext>>,
});

export const useVenueMediaFilterContext = () => {
  const { venueMediaFilterValues, setVenueMediaFilterValues } = useContext(VenueMediaFilterContext);

  const [contextValue, setContextValue] = useState<VenueMediaFilterContext>({
    tagIds: [],
    types: [],
  });

  const hasFiltersApplied = useMemo(() => {
    return !isEqual(venueMediaFilterValues, noFiltersApplied);
  }, [venueMediaFilterValues]);

  const handleClearFilters = () => {
    setVenueMediaFilterValues(noFiltersApplied);
  };

  const venueMediaDefaultValueForContextProvider = {
    venueMediaFilterValues: contextValue,
    setVenueMediaFilterValues: setContextValue,
  };

  return {
    venueMediaDefaultValueForContextProvider,
    venueMediaFilterValues,
    setVenueMediaFilterValues,
    handleClearFilters,
    hasFiltersApplied,
    VenueMediaFilterContext,
  };
};
