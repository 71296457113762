import { useCallback } from 'react';
import { toast } from 'react-toastify';

export const useToast = () => {
  const emitInfoToast = useCallback((toastText: string): void => {
    toast.info(toastText);
  }, []);

  const emitErrorToast = useCallback((toastText: string): void => {
    toast.error(toastText);
  }, []);

  return {
    emitInfoToast,
    emitErrorToast,
  };
};
