import isEqual from 'lodash/isEqual';
import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';

import { VenueUserRole } from 'src/features/venueUsers';
interface VenueUserFilterContext {
  roles?: VenueUserRole[];
}

const noFiltersApplied: VenueUserFilterContext = {
  roles: [],
};

const VenueUserFilterContext = createContext({
  venueUserFilterValues: {} as VenueUserFilterContext,
  setVenueUserFilterValues: {} as Dispatch<SetStateAction<VenueUserFilterContext>>,
});

export const useVenueUserFilterContext = () => {
  const { venueUserFilterValues, setVenueUserFilterValues } = useContext(VenueUserFilterContext);

  const [contextValue, setContextValue] = useState<VenueUserFilterContext>({
    roles: [],
  });

  const hasFiltersApplied = useMemo(() => {
    return !isEqual(venueUserFilterValues, noFiltersApplied);
  }, [venueUserFilterValues]);

  const handleClearFilters = () => {
    setVenueUserFilterValues(noFiltersApplied);
  };

  const venueUserDefaultValueForContextProvider = {
    venueUserFilterValues: contextValue,
    setVenueUserFilterValues: setContextValue,
  };

  return {
    venueUserDefaultValueForContextProvider,
    venueUserFilterValues,
    setVenueUserFilterValues,
    handleClearFilters,
    hasFiltersApplied,
    VenueUserFilterContext,
  };
};
