import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { AxiosError, AxiosInstance } from 'axios';
import { ReactElement, useCallback, useEffect } from 'react';

import { useToast } from 'src/hooks';
import { extractErrorsFromResponse, httpClient } from 'src/lib/axios';

interface InterceptorProviderProps {
  children: ReactElement[] | ReactElement;
}

// TODO: Revisit. Temporary solution to ensure logout on unauthorized (expired) token
export const InterceptorProvider = withAuthenticationRequired(({ children }: InterceptorProviderProps): JSX.Element => {
  const { logout } = useAuth0();
  const { emitErrorToast } = useToast();

  const setupInterceptorsTo = (axiosInstance: AxiosInstance, onResponseError: any): AxiosInstance => {
    axiosInstance.interceptors.response.use(undefined, onResponseError);
    return axiosInstance;
  };

  const onResponseError = useCallback(
    (error: AxiosError) => {
      const { response } = error;

      // on a 401 unauthorized, reject the promise and log the user out
      if (response?.status === 401) {
        emitErrorToast('Unauthorized. You will be logged out.');
        setTimeout(() => {
          logout({ logoutParams: { returnTo: window.location.origin } });
        }, 2000);
      }

      const errorMessages = extractErrorsFromResponse(error as AxiosError);
      emitErrorToast(`There was an error. ${JSON.stringify(errorMessages.length ? errorMessages : '')}`);

      return Promise.reject(error);
    },
    [logout, emitErrorToast]
  );

  useEffect(() => {
    setupInterceptorsTo(httpClient, onResponseError);
  }, [onResponseError]);

  return <>{children}</>;
});
