import isEqual from 'lodash/isEqual';
import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';

import { DAYS_OF_THE_WEEK } from 'src/config';

interface VenuePlaylistFilterContext {
  locationIds?: number[];
  mediaTagIds?: number[];
  locationTagIds?: number[];
  startDate?: string;
  endDate?: string;
  dailyStartTime?: string;
  dailyEndTime?: string;
  daysOfWeek: (keyof typeof DAYS_OF_THE_WEEK)[];
}

const VenuePlaylistFilterContext = createContext({
  venuePlaylistFilterValues: {} as VenuePlaylistFilterContext,
  setVenuePlaylistFilterValues: {} as Dispatch<SetStateAction<VenuePlaylistFilterContext>>,
});

const noFiltersApplied: VenuePlaylistFilterContext = {
  locationIds: [],
  mediaTagIds: [],
  locationTagIds: [],
  startDate: undefined,
  endDate: undefined,
  dailyStartTime: undefined,
  dailyEndTime: undefined,
  daysOfWeek: [],
};

export const useVenuePlaylistFilterContext = () => {
  const { venuePlaylistFilterValues, setVenuePlaylistFilterValues } = useContext(VenuePlaylistFilterContext);

  const [contextValue, setContextValue] = useState<VenuePlaylistFilterContext>({
    locationIds: [],
    mediaTagIds: [],
    locationTagIds: [],
    startDate: undefined,
    endDate: undefined,
    dailyStartTime: undefined,
    dailyEndTime: undefined,
    daysOfWeek: [],
  });

  const hasFiltersApplied = useMemo(() => {
    return !isEqual(venuePlaylistFilterValues, noFiltersApplied);
  }, [venuePlaylistFilterValues]);

  const handleClearFilters = () => {
    setVenuePlaylistFilterValues(noFiltersApplied);
  };

  const venuePlaylistDefaultValueForContextProvider = {
    venuePlaylistFilterValues: contextValue,
    setVenuePlaylistFilterValues: setContextValue,
  };

  return {
    venuePlaylistDefaultValueForContextProvider,
    venuePlaylistFilterValues,
    setVenuePlaylistFilterValues,
    handleClearFilters,
    hasFiltersApplied,
    VenuePlaylistFilterContext,
  };
};
