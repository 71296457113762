import isEqual from 'lodash/isEqual';
import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';

import { BrandMediaStatus } from 'src/features/brandMediaLibrary';

interface BrandMediaFilterContext {
  types?: string[];
  statuses?: BrandMediaStatus[];
}

const noFiltersApplied: BrandMediaFilterContext = {
  types: [],
  statuses: [],
};

const BrandMediaFilterContext = createContext({
  brandMediaFilterValues: {} as BrandMediaFilterContext,
  setBrandMediaFilterValues: {} as Dispatch<SetStateAction<BrandMediaFilterContext>>,
});

export const useBrandMediaFilterContext = () => {
  const { brandMediaFilterValues, setBrandMediaFilterValues } = useContext(BrandMediaFilterContext);

  const [contextValue, setContextValue] = useState<BrandMediaFilterContext>({
    types: [],
    statuses: [],
  });

  const hasFiltersApplied = useMemo(() => {
    return !isEqual(brandMediaFilterValues, noFiltersApplied);
  }, [brandMediaFilterValues]);

  const handleClearFilters = () => {
    setBrandMediaFilterValues(noFiltersApplied);
  };

  const brandMediaDefaultValueForContextProvider = {
    brandMediaFilterValues: contextValue,
    setBrandMediaFilterValues: setContextValue,
  };

  return {
    brandMediaDefaultValueForContextProvider,
    brandMediaFilterValues,
    setBrandMediaFilterValues,
    handleClearFilters,
    hasFiltersApplied,
    BrandMediaFilterContext,
  };
};
