import { ReactElement } from 'react';

import { useBrandContext } from 'src/contexts/activeBrandContext';
import { useCampaignContext } from 'src/contexts/activeCampaignContext';
import { useVenueContext } from 'src/contexts/activeVenueContext';
import { useNavListContext } from 'src/contexts/navListContext';
import { usePageTitleContext } from 'src/contexts/pageTitleContext';

interface ContextProviderProps {
  children: ReactElement[] | ReactElement;
}

export const ContextProvider = ({ children }: ContextProviderProps) => {
  const { brandDefaultValueForContextProvider, ActiveBrandContext } = useBrandContext();
  const { campaignDefaultValueForContextProvider, ActiveCampaignContext } = useCampaignContext();
  const { pageTitleDefaultValueForContextProvider, PageTitleContext } = usePageTitleContext();
  const { venueDefaultValueForContextProvider, ActiveVenueContext } = useVenueContext();
  const { navListDefaultValueForContextProvider, NavListContext } = useNavListContext();

  return (
    <NavListContext.Provider value={navListDefaultValueForContextProvider}>
      <PageTitleContext.Provider value={pageTitleDefaultValueForContextProvider}>
        <ActiveVenueContext.Provider value={venueDefaultValueForContextProvider}>
          <ActiveBrandContext.Provider value={brandDefaultValueForContextProvider}>
            <ActiveCampaignContext.Provider value={campaignDefaultValueForContextProvider}>
              {children}
            </ActiveCampaignContext.Provider>
          </ActiveBrandContext.Provider>
        </ActiveVenueContext.Provider>
      </PageTitleContext.Provider>
    </NavListContext.Provider>
  );
};
