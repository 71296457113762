import isEqual from 'lodash/isEqual';
import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';

import { BrandUserRole } from 'src/features/brandUsers';

interface BrandUserFilterContext {
  roles?: BrandUserRole[];
}

const noFiltersApplied: BrandUserFilterContext = {
  roles: [],
};

const BrandUserFilterContext = createContext({
  brandUserFilterValues: {} as BrandUserFilterContext,
  setBrandUserFilterValues: {} as Dispatch<SetStateAction<BrandUserFilterContext>>,
});

export const useBrandUserFilterContext = () => {
  const { brandUserFilterValues, setBrandUserFilterValues } = useContext(BrandUserFilterContext);

  const [contextValue, setContextValue] = useState<BrandUserFilterContext>({
    roles: [],
  });

  const hasFiltersApplied = useMemo(() => {
    return !isEqual(brandUserFilterValues, noFiltersApplied);
  }, [brandUserFilterValues]);

  const handleClearFilters = () => {
    setBrandUserFilterValues(noFiltersApplied);
  };

  const brandUserDefaultValueForContextProvider = {
    brandUserFilterValues: contextValue,
    setBrandUserFilterValues: setContextValue,
  };

  return {
    brandUserDefaultValueForContextProvider,
    brandUserFilterValues,
    setBrandUserFilterValues,
    handleClearFilters,
    hasFiltersApplied,
    BrandUserFilterContext,
  };
};
