import { ReactElement } from 'react';

import { PermanentDrawer } from 'src/components/Layout/PermanentDrawer';

export interface MainLayoutProps {
  children: ReactElement | ReactElement[];
}

export const MainLayout = ({ children }: MainLayoutProps) => {
  return <PermanentDrawer>{children}</PermanentDrawer>;
};
