import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import CampaignIcon from '@mui/icons-material/Campaign';
import CollectionsIcon from '@mui/icons-material/Collections';
import FoodBankIcon from '@mui/icons-material/FoodBank';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import PanoramaIcon from '@mui/icons-material/Panorama';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PlaceIcon from '@mui/icons-material/Place';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import TabletAndroidIcon from '@mui/icons-material/TabletAndroid';
import { Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { useNavListContext } from 'src/contexts/navListContext';

const listOfBasicRoutes = [
  '/campaigns',
  '/venues',
  '/brands',
  '/users',
  '/pair-devices',
  '/devices',
  '/device-metrics',
  '/ads',
  '/promos',
];

export const NavList = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { navListContent, venuePathName, brandPathName } = useNavListContext();

  if (navListContent === 'venue') {
    return (
      <>
        <List sx={{ backgroundColor: 'primary.main' }} component="nav">
          <ListItemButton onClick={() => navigate(-1)}>
            <ListItemIcon sx={{ color: 'white' }}>
              <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText primary="Back" />
          </ListItemButton>
        </List>
        <List sx={{ backgroundColor: 'primary.main' }} component="nav">
          <ListItemButton onClick={() => navigate(`${venuePathName}/playlist`)}>
            <ListItemIcon sx={{ color: 'white' }}>
              <PlaylistAddIcon />
            </ListItemIcon>
            <ListItemText primary="Playlist" />
          </ListItemButton>
          <Divider />
          <ListItemButton onClick={() => navigate(`${venuePathName}/library`)}>
            <ListItemIcon sx={{ color: 'white' }}>
              <CollectionsIcon />
            </ListItemIcon>
            <ListItemText primary="Library" />
          </ListItemButton>
          <Divider />
          <ListItemButton onClick={() => navigate(`${venuePathName}/tags`)}>
            <ListItemIcon sx={{ color: 'white' }}>
              <LocalOfferIcon />
            </ListItemIcon>
            <ListItemText primary="Tags" />
          </ListItemButton>
          <Divider />
          <ListItemButton onClick={() => navigate(`${venuePathName}/locations`)}>
            <ListItemIcon sx={{ color: 'white' }}>
              <PlaceIcon />
            </ListItemIcon>
            <ListItemText primary="Locations" />
          </ListItemButton>
          <Divider />
          <ListItemButton onClick={() => navigate(`${venuePathName}/users`)}>
            <ListItemIcon sx={{ color: 'white' }}>
              <PersonOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItemButton>
          <ListItemButton onClick={() => navigate(`${venuePathName}/analytics`)}>
            <ListItemIcon sx={{ color: 'white' }}>
              <StackedLineChartIcon />
            </ListItemIcon>
            <ListItemText primary="Analytics" />
          </ListItemButton>
        </List>
      </>
    );
  }

  if (navListContent === 'brand') {
    return (
      <>
        <List sx={{ backgroundColor: 'primary.main' }} component="nav">
          <ListItemButton onClick={() => navigate(-1)}>
            <ListItemIcon sx={{ color: 'white' }}>
              <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText primary="Back" />
          </ListItemButton>
        </List>
        <List sx={{ backgroundColor: 'primary.main' }} component="nav">
          <ListItemButton onClick={() => navigate(`${brandPathName}/campaigns`)}>
            <ListItemIcon sx={{ color: 'white' }}>
              <CampaignIcon />
            </ListItemIcon>
            <ListItemText primary="Campaigns" />
          </ListItemButton>
          <Divider />
          <ListItemButton onClick={() => navigate(`${brandPathName}/library`)}>
            <ListItemIcon sx={{ color: 'white' }}>
              <CollectionsIcon />
            </ListItemIcon>
            <ListItemText primary="Library" />
          </ListItemButton>
          <Divider />
          <ListItemButton onClick={() => navigate(`${brandPathName}/users`)}>
            <ListItemIcon sx={{ color: 'white' }}>
              <PersonOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItemButton>
        </List>
      </>
    );
  }

  // Default nav list view
  return (
    <>
      {!listOfBasicRoutes.includes(pathname) ? (
        <List sx={{ backgroundColor: 'primary.main' }} component="nav">
          <ListItemButton onClick={() => navigate(-1)}>
            <ListItemIcon sx={{ color: 'white' }}>
              <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText primary="Back" />
          </ListItemButton>
        </List>
      ) : null}
      <List sx={{ backgroundColor: 'primary.main' }} component="nav">
        <ListItemButton onClick={() => navigate('/campaigns')}>
          <ListItemIcon sx={{ color: 'white' }}>
            <CampaignIcon />
          </ListItemIcon>
          <ListItemText primary="Campaigns" />
        </ListItemButton>
        <Divider />
        <ListItemButton onClick={() => navigate('/venues')}>
          <ListItemIcon sx={{ color: 'white' }}>
            <FoodBankIcon />
          </ListItemIcon>
          <ListItemText primary="Venues" />
        </ListItemButton>
        <Divider />
        <ListItemButton onClick={() => navigate('/brands')}>
          <ListItemIcon sx={{ color: 'white' }}>
            <BrandingWatermarkIcon />
          </ListItemIcon>
          <ListItemText primary="Brands" />
        </ListItemButton>
        <ListItemButton onClick={() => navigate('/users')}>
          <ListItemIcon sx={{ color: 'white' }}>
            <AdminPanelSettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItemButton>
        <ListItemButton onClick={() => navigate('/pair-devices')}>
          <ListItemIcon sx={{ color: 'white' }}>
            <RssFeedIcon />
          </ListItemIcon>
          <ListItemText primary="Pair Devices" />
        </ListItemButton>
        <ListItemButton onClick={() => navigate('/devices')}>
          <ListItemIcon sx={{ color: 'white' }}>
            <TabletAndroidIcon />
          </ListItemIcon>
          <ListItemText primary="Devices" />
        </ListItemButton>
        <ListItemButton onClick={() => navigate('/device-metrics')}>
          <ListItemIcon sx={{ color: 'white' }}>
            <MonitorHeartIcon />
          </ListItemIcon>
          <ListItemText primary="Uptime" />
        </ListItemButton>
        <ListItemButton onClick={() => navigate('/ads')}>
          <ListItemIcon sx={{ color: 'white' }}>
            <AdsClickIcon />
          </ListItemIcon>
          <ListItemText primary="Quarantine" />
        </ListItemButton>
        <ListItemButton onClick={() => navigate('/promos')}>
          <ListItemIcon sx={{ color: 'white' }}>
            <PanoramaIcon />
          </ListItemIcon>
          <ListItemText primary="Promos" />
        </ListItemButton>
      </List>
    </>
  );
};
