const BASE_CAMPAIGN_PATH = '/admin/brand';

// paths for creating a campaign, and deleting a campaign are in features/campaigns

export const brandCampaignGETPath = (brandId: string | number) => `${BASE_CAMPAIGN_PATH}/${brandId}/campaign`;

export const brandCampaignGETByIDPath = (brandId: string | number, campaignId: string | number) =>
  `${BASE_CAMPAIGN_PATH}/${brandId}/campaign/${campaignId}`;

export const brandCampaignPUTPath = (brandId: number | string, campaignId: number | string) =>
  `${BASE_CAMPAIGN_PATH}/${brandId}/campaign/${campaignId}`;
