import { DateTime } from 'luxon';

const DATE_FORMAT_WITH_TIME = DateTime.DATETIME_SHORT;
const DATE_FORMAT_WITHOUT_TIME = DateTime.DATE_SHORT;
const DATE_FORMAT_WITH_YEAR_FIRST = 'yyyy-MM-dd';

export const useDate = () => {
  /**
   * Takes in a date value of type ISO string to format. If hideTime is true,
   * don't show the time associated with the date I.E startDate
   *
   * If hideTime is false, show the full date and time I.E createdAt
   *
   * @param dateStringInISOFormat
   * @param hideTime
   * @returns
   */
  const formatDate = (dateStringInISOFormat: string, hideTime?: boolean) => {
    if (hideTime) {
      return DateTime.fromISO(dateStringInISOFormat, {}).toLocaleString(DATE_FORMAT_WITHOUT_TIME);
    }

    return DateTime.fromISO(dateStringInISOFormat, {}).toLocaleString(DATE_FORMAT_WITH_TIME);
  };

  /**
   * Takes in a date value to format. If its of type DateTime, format it and return. If its a string, simply return
   *
   * @param dateTime
   * @param format
   * @returns a formatted DateTime object or the date string value
   */
  const formatTime = (dateTime: string | DateTime, format = 'HH:mm:ss') => {
    if (dateTime && DateTime.isDateTime(dateTime)) {
      return dateTime.startOf('minute').toFormat(format);
    }

    return dateTime;
  };

  /**
   *
   * @returns the current date
   */
  const getCurrentDate = () => {
    return DateTime.now().toFormat(DATE_FORMAT_WITH_YEAR_FIRST);
  };

  /**
   *
   * @param date DateTime
   * @param addDays number
   * @returns Given a date and number of days to add, returns that
   * new date in a yyyy-MM-dd format like '2022-06-07'
   */
  const incrementDate = (date: DateTime, addDays: number) => {
    return date.plus({ days: addDays }).toFormat(DATE_FORMAT_WITH_YEAR_FIRST);
  };

  return {
    formatDate,
    formatTime,
    getCurrentDate,
    incrementDate,
  };
};
