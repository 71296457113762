import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#232156',
      dark: '#17163B',
    },
    secondary: {
      main: '#2B89E1',
      dark: '#184C7D',
      light: '#7049EF',
    },
  },
  components: {
    MuiImageListItem: {
      styleOverrides: {
        root: {
          '& .hidden-image-list-item-bar': {
            display: 'none',
          },
          '&:hover .hidden-image-list-item-bar': {
            display: 'flex',
          },
        },
      },
    },
  },
});
