import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

const PageTitleContext = createContext({
  pageTitle: '',
  setPageTitle: {} as Dispatch<SetStateAction<string>>,
});

export const usePageTitleContext = () => {
  const { pageTitle, setPageTitle } = useContext(PageTitleContext);

  const [contextValue, setContextValue] = useState<string>('');

  const pageTitleDefaultValueForContextProvider = {
    pageTitle: contextValue,
    setPageTitle: setContextValue,
  };

  return {
    pageTitleDefaultValueForContextProvider,
    pageTitle,
    setPageTitle,
    PageTitleContext,
  };
};
