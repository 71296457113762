import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { VenueResponse } from 'src/features/venues';
import { venueGETByIDPath } from 'src/features/venues/api/paths';
import { httpClient } from 'src/lib/axios';

type GetVenueByIdDTO = {
  data: {
    venueId: number | string;
  };
};

const getVenueById = async ({ data }: GetVenueByIdDTO): Promise<VenueResponse> => {
  const { venueId } = data;

  const {
    data: { payload },
  } = await httpClient.get(`${venueGETByIDPath(venueId)}`);

  return payload as VenueResponse;
};

export const constructGetVenueByIdDTO = (venueId: string | number | undefined): GetVenueByIdDTO => {
  return {
    data: {
      venueId: venueId as string | number,
    },
  };
};

export const useGetVenue = (payload: GetVenueByIdDTO, options?: UseQueryOptions<VenueResponse>) => {
  const {
    data: { venueId },
  } = payload;

  return useQuery<VenueResponse>({
    queryKey: ['venue', Number(venueId)],
    queryFn: () => getVenueById(payload),
    keepPreviousData: true,
    ...options,
  });
};
