import { ReactElement } from 'react';

import { useBrandCampaignPlaylistFilterContext } from 'src/features/brandCampaignPlaylists/contexts/brandCampaignPlaylistFilterContext';
import { useBrandCampaignFilterContext } from 'src/features/brandCampaigns/contexts/brandCampaignFilterContext';
import { useBrandMediaFilterContext } from 'src/features/brandMediaLibrary/contexts/brandMediaFilterContext';
import { useBrandUserFilterContext } from 'src/features/brandUsers/contexts/brandUserFilterContext';
import { useCampaignFilterContext } from 'src/features/campaigns/contexts/campaignFilterContext';
import { useDeviceFilterContext } from 'src/features/devices/contexts/deviceFilterContext';
import { useLocationFilterContext } from 'src/features/locations/contexts/locationFilterContext';
import { useTagFilterContext } from 'src/features/tags/contexts/tagFilterContext';
import { useVenueMediaFilterContext } from 'src/features/venueMediaLibrary/contexts/venueMediaFilterContext';
import { useVenuePlaylistFilterContext } from 'src/features/venuePlaylists/contexts/venuePlaylistFilterContext';
import { useVenueUserFilterContext } from 'src/features/venueUsers/contexts/venueUserFilterContext';

interface FiltersProviderProps {
  children: ReactElement[] | ReactElement;
}

export const FiltersProvider = ({ children }: FiltersProviderProps) => {
  const { DeviceFilterContext, deviceDefaultValueForContextProvider } = useDeviceFilterContext();
  const { CampaignFilterContext, campaignDefaultValueForContextProvider } = useCampaignFilterContext();
  const { BrandCampaignFilterContext, brandCampaignDefaultValueForContextProvider } = useBrandCampaignFilterContext();
  const { VenueUserFilterContext, venueUserDefaultValueForContextProvider } = useVenueUserFilterContext();
  const { BrandUserFilterContext, brandUserDefaultValueForContextProvider } = useBrandUserFilterContext();
  const { TagFilterContext, tagDefaultValueForContextProvider } = useTagFilterContext();
  const { LocationFilterContext, locationDefaultValueForContextProvider } = useLocationFilterContext();
  const { VenueMediaFilterContext, venueMediaDefaultValueForContextProvider } = useVenueMediaFilterContext();
  const { BrandMediaFilterContext, brandMediaDefaultValueForContextProvider } = useBrandMediaFilterContext();
  const { VenuePlaylistFilterContext, venuePlaylistDefaultValueForContextProvider } = useVenuePlaylistFilterContext();
  const { BrandCampaignPlaylistFilterContext, brandCampaignPlaylistDefaultValueForContextProvider } =
    useBrandCampaignPlaylistFilterContext();

  return (
    <DeviceFilterContext.Provider value={deviceDefaultValueForContextProvider}>
      <CampaignFilterContext.Provider value={campaignDefaultValueForContextProvider}>
        <BrandCampaignFilterContext.Provider value={brandCampaignDefaultValueForContextProvider}>
          <VenueUserFilterContext.Provider value={venueUserDefaultValueForContextProvider}>
            <BrandUserFilterContext.Provider value={brandUserDefaultValueForContextProvider}>
              <TagFilterContext.Provider value={tagDefaultValueForContextProvider}>
                <LocationFilterContext.Provider value={locationDefaultValueForContextProvider}>
                  <VenueMediaFilterContext.Provider value={venueMediaDefaultValueForContextProvider}>
                    <BrandMediaFilterContext.Provider value={brandMediaDefaultValueForContextProvider}>
                      <VenuePlaylistFilterContext.Provider value={venuePlaylistDefaultValueForContextProvider}>
                        <BrandCampaignPlaylistFilterContext.Provider
                          value={brandCampaignPlaylistDefaultValueForContextProvider}
                        >
                          {children}
                        </BrandCampaignPlaylistFilterContext.Provider>
                      </VenuePlaylistFilterContext.Provider>
                    </BrandMediaFilterContext.Provider>
                  </VenueMediaFilterContext.Provider>
                </LocationFilterContext.Provider>
              </TagFilterContext.Provider>
            </BrandUserFilterContext.Provider>
          </VenueUserFilterContext.Provider>
        </BrandCampaignFilterContext.Provider>
      </CampaignFilterContext.Provider>
    </DeviceFilterContext.Provider>
  );
};
