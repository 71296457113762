const BASE_VENUE_PATH = '/venue';

export const venueGETPath = () => `${BASE_VENUE_PATH}`;
export const venueGETByIDPath = (id: number | string) => `${BASE_VENUE_PATH}/${id}`;

export const venuePOSTPath = () => `${BASE_VENUE_PATH}`;

export const venuePUTPath = (id: number | string) => `${BASE_VENUE_PATH}/${id}`;

export const venueDELETEPath = (id: number | string) => `${BASE_VENUE_PATH}/${id}`;
