import { useLayoutEffect, useState } from 'react';

import { useBrandContext } from 'src/contexts/activeBrandContext';
import { constructGetBrandByIdDTO, useGetBrand } from 'src/features/brands/api/getBrand';

export const useBrandName = (brandId: string | undefined): string => {
  const [brandName, setBrandName] = useState<string>('');
  const { activeBrand, setActiveBrand } = useBrandContext();

  const { refetch } = useGetBrand(constructGetBrandByIdDTO(brandId), {
    enabled: false,
    onSuccess: data => {
      setActiveBrand({ id: `${data.id}`, name: data.name });
      setBrandName(`${data.name}`);
    },
  });

  useLayoutEffect(() => {
    if (brandId) {
      if (brandId === activeBrand.id) {
        setBrandName(`${activeBrand.name}`);
      } else {
        refetch();
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return brandName;
};
