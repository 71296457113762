import { AppProvider } from 'src/providers/app';
import { AppRoutes } from 'src/routes';

const App = () => {
  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
};

// eslint-disable-next-line no-restricted-syntax
export default App;
