import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

/**
 * default navListContent is the three main areas of the app. Campaigns, Venues, and Brands
 * venue navListContent is the venue specific area of the app with its own unique nav list
 * brands navListContent is the brand specific area of the app with its own unique nav list
 */
const NavListContext = createContext({
  navListContent: '',
  setNavListContent: {} as Dispatch<SetStateAction<string>>,
  venuePathName: '',
  setVenuePathName: {} as Dispatch<SetStateAction<string>>,
  brandPathName: '',
  setBrandPathName: {} as Dispatch<SetStateAction<string>>,
});

export const useNavListContext = () => {
  const { navListContent, setNavListContent, venuePathName, setVenuePathName, brandPathName, setBrandPathName } =
    useContext(NavListContext);

  const [navListContentValue, setNavListContentValue] = useState<string>('default');
  const [venuePathNameValue, setVenuePathNameValue] = useState<string>('');
  const [brandPathNameValue, setBrandPathNameValue] = useState<string>('');

  const navListDefaultValueForContextProvider = {
    navListContent: navListContentValue,
    setNavListContent: setNavListContentValue,
    venuePathName: venuePathNameValue,
    setVenuePathName: setVenuePathNameValue,
    brandPathName: brandPathNameValue,
    setBrandPathName: setBrandPathNameValue,
  };

  return {
    navListContent,
    setNavListContent,
    venuePathName,
    setVenuePathName,
    brandPathName,
    setBrandPathName,
    navListDefaultValueForContextProvider,
    NavListContext,
  };
};
