import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

interface CampaignContext {
  id: string;
  name: string;
}

const ActiveCampaignContext = createContext({
  activeCampaign: {} as CampaignContext,
  setActiveCampaign: {} as Dispatch<SetStateAction<CampaignContext>>,
});

export const useCampaignContext = () => {
  const { activeCampaign, setActiveCampaign } = useContext(ActiveCampaignContext);

  const [contextValue, setContextValue] = useState<CampaignContext>({
    id: '',
    name: '',
  });

  const campaignDefaultValueForContextProvider = {
    activeCampaign: contextValue,
    setActiveCampaign: setContextValue,
  };

  return {
    campaignDefaultValueForContextProvider,
    activeCampaign,
    setActiveCampaign,
    ActiveCampaignContext,
  };
};
