import { useLayoutEffect, useState } from 'react';

import { useVenueContext } from 'src/contexts/activeVenueContext';
import { constructGetVenueByIdDTO, useGetVenue } from 'src/features/venues/api/getVenue';

export const useVenueName = (venueId: string | undefined): string => {
  const [venueName, setVenueName] = useState<string>('');

  const { activeVenue, setActiveVenue } = useVenueContext();

  const { refetch } = useGetVenue(constructGetVenueByIdDTO(venueId), {
    enabled: false,
    onSuccess: data => {
      setActiveVenue({
        id: `${data.id}`,
        name: data.name,
        venueType: data.venueType,
        mediaOrientation: data.mediaOrientation,
      });
      setVenueName(`${data.name} |`);
    },
  });

  useLayoutEffect(() => {
    if (venueId) {
      if (venueId === activeVenue.id) {
        setVenueName(`${activeVenue.name} |`);
      } else {
        refetch();
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return venueName;
};
