import isEqual from 'lodash/isEqual';
import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';

import { TagType } from 'src/features/tags';

interface TagFilterContext {
  types?: TagType[];
}

const noFiltersApplied: TagFilterContext = {
  types: [],
};

const TagFilterContext = createContext({
  tagFilterValues: {} as TagFilterContext,
  setTagFilterValues: {} as Dispatch<SetStateAction<TagFilterContext>>,
});

export const useTagFilterContext = () => {
  const { tagFilterValues, setTagFilterValues } = useContext(TagFilterContext);

  const [contextValue, setContextValue] = useState<TagFilterContext>({
    types: [],
  });

  const hasFiltersApplied = useMemo(() => {
    return !isEqual(tagFilterValues, noFiltersApplied);
  }, [tagFilterValues]);

  const handleClearFilters = () => {
    setTagFilterValues(noFiltersApplied);
  };

  const tagDefaultValueForContextProvider = {
    tagFilterValues: contextValue,
    setTagFilterValues: setContextValue,
  };

  return {
    tagDefaultValueForContextProvider,
    tagFilterValues,
    setTagFilterValues,
    handleClearFilters,
    hasFiltersApplied,
    TagFilterContext,
  };
};
