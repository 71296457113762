import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button } from '@mui/material';

import logoNoText from 'src/assets/logoNoText.png';

interface GenericErrorPageProps {
  error: Error | undefined;
}

export const GenericErrorPage = ({ error }: GenericErrorPageProps) => {
  const { logout } = useAuth0();

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <div>
        <img src={logoNoText} alt="logo" width="280" height="160" />
        <p>An unexpected error occured.</p>
        <pre style={{ color: 'red' }}>Error message: {error?.message ?? ''}</pre>
        <p>
          Please refresh your browser or
          <Button
            onClick={() => {
              logout({ logoutParams: { returnTo: window.location.origin } });
            }}
          >
            Logout
          </Button>
        </p>
      </div>
    </Box>
  );
};
