import isEqual from 'lodash/isEqual';
import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';

interface BrandCampaignFilterContext {
  statuses?: string[];
  dateLow?: string;
  dateHigh?: string;
}

const noFiltersApplied: BrandCampaignFilterContext = {
  statuses: [],
  dateLow: undefined,
  dateHigh: undefined,
};

const BrandCampaignFilterContext = createContext({
  brandCampaignFilterValues: {} as BrandCampaignFilterContext,
  setBrandCampaignFilterValues: {} as Dispatch<SetStateAction<BrandCampaignFilterContext>>,
});

export const useBrandCampaignFilterContext = () => {
  const { brandCampaignFilterValues, setBrandCampaignFilterValues } = useContext(BrandCampaignFilterContext);

  const [contextValue, setContextValue] = useState<BrandCampaignFilterContext>({
    statuses: ['ACTIVE', 'UPCOMING'],
    dateLow: undefined,
    dateHigh: undefined,
  });

  const hasFiltersApplied = useMemo(() => {
    return !isEqual(brandCampaignFilterValues, noFiltersApplied);
  }, [brandCampaignFilterValues]);

  const handleClearFilters = () => {
    setBrandCampaignFilterValues(noFiltersApplied);
  };

  const brandCampaignDefaultValueForContextProvider = {
    brandCampaignFilterValues: contextValue,
    setBrandCampaignFilterValues: setContextValue,
  };

  return {
    brandCampaignDefaultValueForContextProvider,
    brandCampaignFilterValues,
    setBrandCampaignFilterValues,
    handleClearFilters,
    hasFiltersApplied,
    BrandCampaignFilterContext,
  };
};
