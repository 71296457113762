import { BasicTablePropertiesToAccess, HeadCell } from 'src/types';

export const CampaignTableHeadCells: readonly HeadCell[] = [
  {
    id: 'name',
    align: 'left',
    disablePadding: true,
    label: 'Name',
    sorting: {
      isSortable: true,
      isDefaultSort: true,
    },
  },
  {
    id: 'brand',
    align: 'left',
    disablePadding: false,
    label: 'Brand',
    sorting: {
      isSortable: false,
    },
  },
  {
    id: 'actions',
    align: 'left',
    disablePadding: false,
    label: 'Actions',
    sorting: {
      isSortable: false,
    },
  },
  {
    id: 'allocation',
    align: 'left',
    disablePadding: false,
    label: 'Allocation',
    sorting: {
      isSortable: false,
    },
  },
  {
    id: 'status',
    align: 'left',
    disablePadding: false,
    label: 'Status',
    sorting: {
      isSortable: false,
    },
  },
  {
    id: 'start_date',
    align: 'left',
    disablePadding: false,
    label: 'Start Date',
    sorting: {
      isSortable: true,
    },
  },
  {
    id: 'end_date',
    align: 'left',
    disablePadding: false,
    label: 'End Date',
    sorting: {
      isSortable: true,
    },
  },
  {
    id: 'progress',
    align: 'left',
    disablePadding: false,
    label: 'Progress',
    sorting: {
      isSortable: false,
    },
  },
  {
    id: 'created_at',
    align: 'left',
    disablePadding: false,
    label: 'Created At',
    sorting: {
      isSortable: false,
    },
  },
];

export const CampaignLocationProperties: BasicTablePropertiesToAccess[] = [
  {
    name: 'venue',
    prefix: '',
    suffix: '',
  },
  { name: 'location', prefix: '', suffix: '' },
];

export const CampaignLocationSelectTableHeadCells: readonly HeadCell[] = [
  {
    id: 'venue',
    align: 'left',
    disablePadding: true,
    label: 'Venue',
    sorting: {
      isSortable: true,
      isDefaultSort: false,
    },
  },
  {
    id: 'location',
    align: 'left',
    disablePadding: false,
    label: 'Location',
    sorting: {
      isSortable: false,
    },
  },
];

export const onSuccessCampaignsNavigateTo = () => '/campaigns';
