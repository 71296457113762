import { useLayoutEffect, useState } from 'react';

import { useCampaignContext } from 'src/contexts/activeCampaignContext';
import {
  constructGetBrandCampaignByIdDTO,
  useGetBrandCampaign,
} from 'src/features/brandCampaigns/api/getBrandCampaign';

export const useCampaignName = (brandId: string | undefined, campaignId: string | undefined): string => {
  const [campaignName, setCampaignName] = useState<string>('');

  const { activeCampaign, setActiveCampaign } = useCampaignContext();

  const { refetch } = useGetBrandCampaign(constructGetBrandCampaignByIdDTO(brandId, campaignId), {
    enabled: false,
    onSuccess: data => {
      setActiveCampaign({ id: `${data.id}`, name: data.name });
      setCampaignName(`${data.name}`);
    },
  });

  useLayoutEffect(() => {
    if (campaignId && brandId) {
      if (campaignId === activeCampaign.id) {
        setCampaignName(`${activeCampaign.name}`);
      } else {
        refetch();
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return campaignName;
};
